<!--
 * @Description: 侧边功能导航通用组件
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-18 14:57:36
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-15 19:14:17
-->
<template>
  <div class="sidebar">
    <div class="module">
      <template v-if="isShowEdit">
        <div class="module-box" :class="{'active': activeTab === 'edit'}"   @click="handleEditClick()">
          <div class="iconfont">&#xe611;</div>
          <div class="title">题型</div>
        </div>
      </template>
      <template v-if="isShowLibrary">
        <div class="module-box" :class="{'active': activeTab === 'library'}"   @click="handleLibraryClick()">
          <div class="iconfont">&#xe600;</div>
          <div class="title">题库</div>
        </div>
      </template>
      <template v-if="isShowSetting">
        <div class="module-box" :class="{'active': activeTab === 'setting'}" @click="handleSettingClick()">
          <div id="setting" class="iconfont">&#xe631;</div>
          <div class="title">问卷设置</div>
        </div>
      </template>
      <template v-if="isShowCycle">
        <div class="module-box">
          <div class="iconfont">&#xe62a;</div>
          <div class="title">答卷详情</div>
        </div>
      </template>
      <template v-if="isShowAnalysis">
        <div class="module-box">
          <div id="setting" class="iconfont">&#xe7fb;</div>
          <div class="title">渠道分析</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { ref } from 'vue'
import { message } from 'ant-design-vue';
import globalQuestionnaire from '@/class/useQuestionnaire'
/**
 * 问卷编辑导航栏点击事件
 */
const handleClickEffect = (emit) => {
  const route = useRoute()
  const router = useRouter()
  const routeName = route.name
  const activeTab = ref('')
  const questionnaire = globalQuestionnaire.getQuestionnaire()

  // TODO: 已下点击tab页来进行交互的逻辑过于混乱，待优化！！
  if (route.query.module && route.query.module === 'library') activeTab.value = 'library'
  else if (routeName === 'QuestionEdit') activeTab.value = 'edit'
  else if (routeName === 'QuestionSetting') activeTab.value = 'setting'
  // 问卷编辑
  const handleEditClick = () => {
    const id = route.params.id
    const isKeepAnswerAndEdit = route.query.isKeepAnswerAndEdit
    emit('getSideBarClickInfo', 'edit')
    if (activeTab.value === 'edit' || activeTab.value === 'library') {
      activeTab.value = 'edit'
      return 
    }
    emit('save')
    if (isKeepAnswerAndEdit) {
      router.push(`/edit/questionEdit/${id}?isKeepAnswerAndEdit=true`)
    } else {
      router.push(`/edit/questionEdit/${id}`)      
    }
  }
  // 题库设置
  const handleLibraryClick = () => {
    const id = route.params.id
    const isKeepAnswerAndEdit = route.query.isKeepAnswerAndEdit
    if (activeTab.value === 'setting') {
      activeTab.value = 'library'
      emit('getSideBarClickInfo', 'library')
      emit('save')
      if (isKeepAnswerAndEdit) {
        router.push(`/edit/questionEdit/${id}?isKeepAnswerAndEdit=true&module=library`)
      } else {
        router.push(`/edit/questionEdit/${id}?module=library`)      
      }
      
    }
    activeTab.value = 'library'
    emit('getSideBarClickInfo', 'library')
    
  }
  // 问卷设置
  const handleSettingClick = () => {
    const id = route.params.id
    const isKeepAnswerAndEdit = route.query.isKeepAnswerAndEdit
    if (activeTab.value === 'setting') return
    else if ((activeTab.value === 'edit' || activeTab.value === 'library') && id === 'new') {
      message.warning('请先保存问卷', 1)
      return
    }
    emit('save')
    activeTab.value = 'setting'
    if (isKeepAnswerAndEdit) {
      router.push(`/edit/questionSetting/${id}?isKeepAnswerAndEdit=true`)
    } else {
      router.push(`/edit/questionSetting/${route.params.id}`)
    }
  }
  return {
    activeTab,
    handleEditClick, 
    handleLibraryClick,
    handleSettingClick
  }
}
export default {
  name: 'Nav',
  props: ['isShowEdit', 'isShowLibrary', 'isShowCycle', 'isShowSetting', 'isShowAnalysis'],
  emits: ['save', 'handleShowLibraryBar'],
  setup(props, { emit }) {
    const { activeTab, handleEditClick, handleLibraryClick, handleSettingClick } = handleClickEffect(emit)
    return {
      activeTab,
      handleEditClick, 
      handleLibraryClick,
      handleSettingClick
    }
  }
}
</script>

<style scoped lang="less">
.sidebar {
  width: 121px;
  background-color: #fff;
  border-right: 3px solid #eff2f5;
  z-index: 99;
}
.module {
  padding-top: 32px;
  color: #575758;
  .active {
    background-color: #00A0E9;
    color: #fff;
  }
  .module-box {
    display: flex;
    width: 100%;
    height: 49px;
    line-height: 49px;
    text-align: center;
    font-weight: 100;
    padding-left: 15px;
    cursor:pointer;
    &:hover {
      background-color: #00A0E9;
      color: #fff;
    }
  }
}
.iconfont {
  font-size: 21px;
  margin-right: 2px;
}
</style>