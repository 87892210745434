<!--
 * @Description: 
 * @version: 
 * @Author: HWL
 * @Date: 2021-05-13 14:58:07
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-29 11:21:44
-->
<template>
  <div class="setting">
    <Header
      :isShowPersonal="true"
      :isShowFunction="true"
      :isShowHelp="true"
      @save="save"
      @publish="publish"
    />
    <div class="center">
      <div class="content">
        <div class="left-content">
          <SideBar 
            :isShowEdit="true"
            :isShowSetting="true"
            :isShowLibrary="true"
            @save="save"
          />
        </div>

        <div class="right-content">
          <a-page-header
            class="header"
            title="有效问卷设置"
            sub-title="（不符合以下条件的问卷将被设置为 无效问卷）"
          />
          <ul>
            <li>
              <a-switch v-model:checked="setting.value.allowDeviceRepeat"/>
              填答设备允许重复
            </li>
            <li>
              <a-switch v-model:checked="setting.value.allowIPRepeat"/>
              填答IP允许重复
            </li>
            <li>
              <a-switch v-model:checked="setting.value.allowResumeOnBreak"/>
              允许断点续答
            </li>
            <li style="padding-bottom: 0px">
              <ul style="list-style: none;">
                <li>
                  <a-switch v-model:checked="setting.value.allowValidDuration"/>
                  有效填答时长
                </li>
                <li style="margin-left:42px; color: #7F7F88;padding: 0;">
                  填写时间不少于
                  <a-input class="input" type="number" placeholder="大于0的整数" v-model:value="setting.value.validDuration"/>
                  秒
                </li>
              </ul>
            </li>
            <li>
              <ul style="list-style: none;">
                <li>
                  <a-switch v-model:checked="setting.value.allowValidQuestionnaireNum"/>
                  有效问卷数量
                </li>
                <li style="margin-left:42px; color: #7F7F88;padding: 0;">
                  当有效问卷数量达到
                  <a-input class="input" type="number" placeholder="大于0的整数" v-model:value="setting.value.validQuestionnaireNum"/>
                  时，系统自动暂停派发问卷
                </li>
              </ul>
            </li>
          </ul>
          <a-page-header
            class="header"
            title="结束语设置"
          />
          <a-input class="input bottom-input" placeholder="请填写结束语" v-model:value="setting.value.concludingRemarks"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/common/QuestionHeader'
import SideBar from '@/components/common/QuestionSideBar'
import questioniSettingEffect from './questionSettingEffect'


export default {
  components: { 
    Header, 
    SideBar,
  },
  setup () {
    const { save, publish, setting } = questioniSettingEffect()

    return {
      setting,
      publish,
      save
    }
  }
}
</script>
<style lang="less" scoped>
.setting {
  width: 100vw;
  height: 100vh;
  .center {
    position: fixed;
    top: 122px;
    width: 100%;
    height: calc(100% - 100px);
    overflow: hidden;
    .content {
      display: flex;
      width: 1300px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .left-content {
        background: #fff;
      }
      .right-content {
        width: 1171px;
        height: 100%;
        background: #fff;
        padding: 61px 50px 0 50px;
        margin-left: 4px;
        .header {
          padding: 30px 0;
          ::v-deep(.ant-page-header-heading-sub-title) {
            font-size: 16px;
            color: #00A0E9;
          }
        }
        li {
          width: 500px;
          line-height: 32px;
          padding: 10px 0;
        }
        .input {
          height: 30px;
          width: 102px;
          border: 1px solid #E1E1E8;
        }
      }
    }
    .input.bottom-input {
      height: 41px;
      width: 1067px;
    }
    .ant-switch-checked {
      background-color: #00A0E9;
    }
    .ant-modal-content {
      position: relative;
      border: 16px solid #eff2f5;
      border-radius: 16px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .ant-modal-header {
        border: none;
        .ant-modal-title {
          font-size: 20px;
        }
      }
      .ant-modal-body {
        .close {
          position: absolute;
          top: 8px;
          right: 24px;
          font-size: 20px;
          .pointer()
        }
        .ant-input-affix-wrapper {
          border: none;
          svg {
            font-size: 18px;
          }
        }
        input {
          border: none;
          border-bottom: 1px solid #d7d7d7;
        }
      }
      .ant-modal-footer {
        border: none;
        div {
          text-align: center;
          button {
            background-color: #f59a23;
            border: none;
            color: #fff;
            &:nth-child(1) {
              display: none;
            }
          }
        }
      }
    }
    :deep(.ant-page-header-heading-title) {
      font-size: 1.5rem !important;
    }
    :deep(.ant-switch) {
      margin-right: 7px;
    }
  }
}
</style>
