/*
 * @Description: 账号相关api
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-09 16:01:22
 * @LastEditors: PSG
 * @LastEditTime: 2021-03-30 12:08:17
 */
import service from '@/utils/request.js'

export function getLogin(data) {
  return service.request({
    url: '/open/login/password',
    method: 'post',
    data
  })
}


export function getLogout() {
  return service.request({
    url: '/sso/logout',
    method: 'get',
  })
}
