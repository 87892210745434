/*
 * @Description: 问卷设置操作
 * @version: 
 * @Author: HWL
 * @Date: 2021-07-12 17:09:02
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-16 11:31:18
 */
import { useRoute, useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { getQuestionnaireSettingById, saveQuestionnaireSetting, releaseQuestionnaire } from '@/api/questionnaire'
import { onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue'

/**
 * 获取问卷设置
 */
 const getQuestionnaireSettingEffect = async () => {
  const route = useRoute()
  const id = route.params.id
  const result = await getQuestionnaireSettingById(id)
  if (result.status === 200 && result.data?.code === '00000' && result?.data?.data) {
    return result.data.data
  } else {
    message.error(' 请求答卷配置失败，失败原因：' + result.data.msg)
  }
}

const getQuestionnaireSettingAsync = async () => {
  console.log(this)
  const result = await getQuestionnaireSettingEffect()
  if (result) {
    setting.value = result
  }
}

// 关闭提醒
const linster = (e) => {
  const confirmationMessage = () => {}
  (e || window.event).returnValue = confirmationMessage
  return confirmationMessage
}

const setting = reactive({
  value: {}
})
function questionSettingEffect() {
  console.log(this)
  const isChange = ref(false)

  const route = useRoute()
  const router = useRouter()
  onMounted(getQuestionnaireSettingAsync())

  let firstUpdate = false

  watch(() => setting, (oldValue, newValue) => {
    if (firstUpdate) {
      isChange.value = true
    }
    firstUpdate = true
  }, {
    deep: true
  })

  watch(isChange, (newValue, oldValue) => {
    if (newValue) {
      window.addEventListener('beforeunload', linster, false)
    } else {
      window.removeEventListener('beforeunload', linster, false)
    }
  })

  onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', linster, false)
  })

  const save = async () => {
    isChange.value = false
    const id = route.params.id
    const res = await saveQuestionnaireSetting(id, setting.value)
    if (res.status === 200) {
      if (res.data?.code === '00000') {
        message.success('问卷设置保存成功', 1)
      } else {
        message.error('保存失败，失败原因：' + res.data.msg)
      }
    } else {
      message.error('保存失败，失败原因：' + res.msg)
    }
  }

  const publish = async () => {
    isChange.value = false
    const id = route.params.id
    const res = await saveQuestionnaireSetting(id, setting.value)
    if (res.status === 200) {
      if (res.data?.code === '00000') {
        message.success('问卷设置保存成功', 1)
        let resTwo = await releaseQuestionnaire(id)
        if (resTwo.data?.code === '00000') {
          message.success(`发布成功`)
          router.push(`/detail/${id}/dispatchQuestion`)
        } else {
          message.error(`发布失败，失败原因：${res.msg}`)
        }
      } else {
        message.error('保存失败，失败原因：' + res.data.msg)
      }
    } else {
      message.error('保存失败，失败原因：' + res.msg)
    }
  }

  return {
    save,
    publish,
    setting,
  }
}

export default questionSettingEffect
